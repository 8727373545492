<template>
   <div class="w-full flex justify-between p-4 items-center flex-col h-full text-center">
            <p class="text-white pt-4">To link your Telegram, simply use our auth bot:</p>

            <div>
              <div class="w-full flex flex-col justify-center items-center pt-4">
                <div class="w-full max-w-md p-4 rounded-md border border-white/20 shadow-turquoise-900/50 shadow-2xl bg-woodsmoke-800">
                  <div class="w-full grid grid-cols-[auto_1fr_auto] justify-center items-center gap-x-4">
                    <div class="w-20 h-20 flex-shrink-0 rounded-full p-1 border border-white/20 bg-white/10">
                      <img class="w-full h-full object-cover rounded-full" src="~/assets/auth_botpic.png" alt="" />
                    </div>

                    <div class="w-full">
                      <div class="text-left">
                        <p class="text-xl text-white">Oxbull Auth Bot</p>
                        <p>@oxb_auth_bot</p>
                        <div class="flex justify-start items-center w-full mt-4">
                          <a :href="telegramUrl" target="_blank" class="btn m-0 min-h-0 lowercase h-auto text-sm bg-woodsmoke-700 p-1 px-2 rounded-md text-turquoise-500 flex justify-start items-center space-x-2 border border-white/20">
                            <p>{{ "t.me/oxb_auth_bot" }}</p>
                            <!-- <span class="iconify" data-icon="akar-icons:link"></span> -->
                            <Icon name="akar-icons:link" />
                          </a>

                          <button @click="copyLink()" class="btn btn-circle btn-sm btn-ghost text-turquoise-500 ml-1">
                            <!-- <span class="iconify" data-icon="akar-icons:copy"></span> -->
                            <Icon name="akar-icons:copy" />
                          </button>
                        </div>
                        <p class="text-sm text-woodsmoke-400 capitalize pt-1">↑ launch bot in Telegram</p>
                      </div>
                    </div>

                    <div class="hidden sm:flex justify-center items-center flex-col">
                      <div class="w-[80px] rounded-md overflow-hidden">
                        <ClientOnly>
                          <vue-qrcode :color="{ dark: '#000000ff', light: '#ffffffff' }" type="image/png" :width="80" :value="telegramUrl" />
                        </ClientOnly>
                      </div>

                      <div class="break-words text-sm text-center leading-3 pt-4">
                        <!-- <button class="btn btn-link btn-xs text-turquoise-500 text-xs capitalize">Open Telegram</button> -->
                        Tg On Mobile?
                        <br />
                        Scan to Launch
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-8 text-sm space-y-2 w-full max-w-md text-left mb-4">
                  <p class="text-turquoise-500 text-base">Important:</p>
                  <p>
                    ● Ensure you're interacting with the correct bot by verifying its username:
                    <span class="text-turquoise-200">@oxb_auth_bot</span>
                  </p>
                  <p>● If launching in Telegram fails, close the telegram app and retry.</p>
                  <p>● For Web3 wallets like Metamask and Trust Wallet, which block direct launches from Telegram, copy and paste the link into your browser to access manually.</p>
                </div>
              </div>
            </div>
            <!-- <div class="w-full">
              <button @click="showBotProfile = false" class="btn w-full bg-turquoise-500 text-turquoise-900 hover:text-white">Close</button>
            </div> -->
          </div>
</template>

<script lang="ts" setup>


import VueQrcode from "vue-qrcode";
const telegramUrl = "tg://resolve?domain=oxb_auth_bot&start=auth";
import copy from "copy-to-clipboard";

const copyLink = () => {
  copy(telegramUrl);
  alert("Copied to clipboard!");
};
</script>

<style>

</style>